<template>
  <ErrorFrame>
    <div class="flex flex-col gap-sm">
      <div>
        {{ t('formFields.error.unknown.text') }}
      </div>
      <div>
        {{ t('formFields.error.unknown.tryLater') }}
      </div>
    </div>
  </ErrorFrame>
</template>
<script setup lang="ts">
import ErrorFrame from '@/components/formFields/components/errorFrame/errorFrame.vue';

const { t } = useTrans();
</script>
